import React, { useEffect, useRef, useState } from "react"
import { graphql } from "gatsby"
import ReactMarkdown from "react-markdown"
import rehypeRaw from "rehype-raw"
import PropTypes from "prop-types"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import Layout from "../../components/Layout"
import Slider from "react-slick"
import { GatsbySeo } from "gatsby-plugin-next-seo"
import useMetadata from "../../hooks/useMetadata"
import useLandingImage from "../../hooks/useLandingImage"
import Benefit from "../../components/join-us/Benefit"
import LocationSlide from "../../components/LocationSlide"
import useLocationData from "../../hooks/useLocationData"
import Job from "../../components/join-us/Job"
import useLocationJobData from "../../hooks/useLocationJobData"

const JoinUs = ({ data, location }) => {
  let pathname = ""
  if (location) {
    pathname = location.pathname
  }
  const { joinUs, benefits, lawyers } = data
  const { title, excerpt, description, descriptionImage, benefitsTitle } =
    joinUs
  const { locations } = useLocationData()
  const { locationJobs } = useLocationJobData()

  const [locationNavIndex, setLocationNavIndex] = useState(0)
  const [locationNavStyle, setLocationNavStyle] = useState({
    width: 0,
    left: 0,
  })
  const [isSticky, setIsSticky] = useState(false)
  const locationRefs = useRef(locations.map(() => React.createRef()))
  const currentSubMenuRef = useRef(null)
  const handleScroll = () => {
    let scrollTop = window.pageYOffset
    let currentIndex = 0
    const offset = 250
    const sections = locationRefs.current
    sections.forEach((section, index) => {
      const offsetTop = section.current.offsetTop - offset
      if (scrollTop >= offsetTop) {
        currentIndex = index
      }
    })

    setLocationNavIndex(currentIndex)
    setLocationNavStyle({
      width: currentSubMenuRef.current.offsetWidth,
      left: currentSubMenuRef.current.offsetLeft,
    })
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    return () => window.removeEventListener("scroll", handleScroll)
  })
  useEffect(() => {
    const handleStickyScroll = () => {
      if (window.pageYOffset >= 2900) {
        setIsSticky(true)
      } else {
        setIsSticky(false)
      }
    }

    handleStickyScroll()
    return handleStickyScroll()
  })

  const settings = {
    infinite: true,
    slidesToScroll: 2,
    autoplay: true,
    autoplaySpeed: 0,
    pauseOnHover: false,
    cssEase: "linear",
    slidesToShow: 8,
    speed: 15000,
    rows: 2,
    dots: false,
    className: "slider",
    initialSlide: -1.5,
    responsive: [
      {
        breakpoint: 3860,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 2560,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1.5,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          speed: 8000,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1,
          speed: 6000,
        },
      },
    ],
  }
  const lawyerList = [...lawyers.edges, ...lawyers.edges]

  const { defaultOGImage } = useLandingImage()
  const { siteUrl } = useMetadata()
  const canonical = `${siteUrl}/join-us`

  const SEO = () => {
    return (
      <GatsbySeo
        title={title}
        description={excerpt?.excerpt}
        canonical={canonical}
        openGraph={{
          url: canonical,
          title: title,
          description: excerpt?.excerpt,
          images: [
            {
              url: `https:${defaultOGImage.images.fallback.src}`,
              width: 1200,
              height: 630,
              alt: title,
            },
          ],
          type: "website",
          locale: "zh_tw",
        }}
      />
    )
  }
  const JobList = locationJobs.map((location, index) => (
    <Job
      location={location}
      locationRefs={locationRefs}
      index={index}
      key={index}
    />
  ))
  return (
    <>
      <Layout transParentMenu={false} pathname={pathname}>
        <SEO />
        <div className={`mt-nav`}>
          <div className="relative flex items-center justify-center">
            <div className="w-full h-full">
              <Slider {...settings}>
                {lawyerList.map(lawyer => {
                  const hasProfileImage = lawyer.node.image.title !== "hidden"
                  if (hasProfileImage) {
                    return (
                      <div key={lawyer.node.id}>
                        <GatsbyImage
                          loading={"eager"}
                          image={lawyer.node.image.gatsbyImageData}
                          alt={lawyer.node.name}
                        />
                      </div>
                    )
                  }
                })}
              </Slider>
            </div>
            <div className="absolute w-full container px-6 sm:px-8 flex justify-center">
              <h1
                style={{
                  textShadow: "0 0 2px rgba(0, 0, 0, 0.8)",
                }}
                className="bg-gray-700 bg-opacity-75 text-center text-xl sm:text-4xl text-white font-black p-6 sm:p-8 lg:p-12 join-title title-border border-0"
              >
                {title}
              </h1>
            </div>
          </div>
        </div>
        <BaseSection className={`flex`}>
          <div className="mr-3 sm:mr-12 sm:my-auto">
            <StaticImage
              src={"../../assets/img_quote.svg"}
              alt={"quote"}
              className="w-6 sm:w-16 h-auto"
              placeholder={"blurred"}
              quality={100}
            />
          </div>
          <p className="text-gray-500 my-auto sm:text-base lg:text-xl">
            {excerpt?.excerpt}
          </p>
        </BaseSection>

        <div className="mb-10 lg:mb-24 container mx-auto px-6 sm:px-2">
          <div className="text-center">
            <h2 className="title-border mb-12">{benefitsTitle}</h2>
          </div>
          <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-6 sm:gap-x-0">
            <Benefit benefits={benefits} />
          </div>
        </div>

        <div className="location-lawyer py-16 lg:pb-20 bg-gray-200">
          <div className="sm:container sm:mx-auto sm:px-8">
            <div className="sm:flex">
              <div className="phone:sticky phone:top-nav phone:z-20 sm:w-2/5 lg:w-1/4 sm:pr-12">
                <nav
                  className={`location-nav bg-gray-200 sticky top-nav z-10 ${
                    isSticky
                      ? "bg-white sm:bg-gray-200 transition duration-300 ease-out"
                      : ""
                  }`}
                >
                  <div className="phone:container phone:px-6">
                    <ul
                      className={`relative w-full p-0 flex sm:block sm:pt-4 bg-gray-200 ${
                        isSticky
                          ? "bg-white sm:bg-gray-200 transition duration-300 ease-out"
                          : ""
                      }`}
                    >
                      <LocationSlide
                        locations={locations}
                        locationNavIndex={locationNavIndex}
                        currentSubMenuRef={currentSubMenuRef}
                        pageName={"join-us"}
                      />
                      <div
                        className="nav-active-bar phone:border-b phone:border-primary phone:absolute phone:bottom-0 phone:left-0 phone:w-full phone:z-10 phone:transition-all phone:duration-300 phone:ease-in phone:w-20"
                        style={locationNavStyle}
                      />
                    </ul>
                  </div>
                </nav>
              </div>
              <div className="sm:w-3/5 lg:w-3/4">
                <div className="lawyer-list mt-8 sm:pl-12 sm:mt-0 lg:pl-0">
                  <div className="phone:container phone:px-6 divide-y divide-gray-300 grid gap-12 sm:gap-16">
                    {JobList}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <BaseSection className="grid lg:grid-cols-2 gap-12">
          <GatsbyImage
            className="w-full h-auto"
            image={descriptionImage.gatsbyImageData}
            alt="喆律團隊"
            imgStyle={{
              objectPosition: "top center",
            }}
          />
          <div className="flex items-center">
            <ReactMarkdown
              className="text-gray-700 text-base"
              rehypePlugins={[rehypeRaw]}
            >
              {description.description}
            </ReactMarkdown>
          </div>
        </BaseSection>
      </Layout>
    </>
  )
}
const BaseSection = ({ children, className = "" }) => {
  return (
    <div className={`container mx-auto py-16 lg:py-24 ${className}`}>
      {children}
    </div>
  )
}
BaseSection.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
}
JoinUs.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
}

export default JoinUs

export const pageQuery = graphql`
  query {
    lawyers: allContentfulLawyer(sort: { fields: order, order: ASC }) {
      edges {
        node {
          id
          name
          image {
            title
            gatsbyImageData(quality: 50, placeholder: BLURRED)
          }
        }
      }
    }

    joinUs: contentfulJoinUs(slug: { eq: "join-us" }) {
      title
      excerpt {
        excerpt
      }
      description {
        description
      }
      descriptionImage {
        gatsbyImageData(placeholder: BLURRED, quality: 100)
      }
      benefitsTitle
      contact {
        link
        description {
          description
        }
        qrCode {
          title
          gatsbyImageData(placeholder: BLURRED, quality: 100)
          file {
            url
          }
        }
      }
    }

    benefits: allContentfulBenefit(sort: { fields: order, order: ASC }) {
      nodes {
        id
        icon {
          title
          file {
            url
          }
        }
        title
        description {
          description
        }
      }
    }
  }
`
