import React from "react"
import PropTypes from "prop-types"
import { AnchorLink } from "gatsby-plugin-anchor-links"

const LocationItem = ({
  location: { locationName, address, phone },
  isNavActive,
  currentSubMenuRef,
  pageName,
}) => {
  return (
    <li
      className={`phone:w-20 phone:inline phone:text-center phone:h-10 phone:flex phone:items-center phone:justify-center sm:block sm:mb-5 sm:last:mb-0 ${
        isNavActive ? "current" : ""
      }`}
      ref={isNavActive ? currentSubMenuRef : null}
    >
      <AnchorLink
        to={`/${pageName}#${locationName}`}
        title={locationName}
        stripHash
      >
        <div className="list-title sm:flex sm:items-center">
          <h4
            className={`font-serif sm:text-2xl sm:transition-all sm:duration-300 sm:ease-out lg:text-3xl sm:hover:text-gray-700 ${
              isNavActive ? "text-gray-700" : "text-gray-400"
            }`}
          >
            {locationName}
          </h4>
        </div>
        <div className="list-description phone:hidden sm:overflow-hidden sm:transition-all sm:duration-500 sm:ease-out">
          <p className="text-sm text-gray-500 mt-2 lg:text-base">{phone}</p>
          <p className="text-sm text-gray-500 mt-1 lg:text-base">{address}</p>
        </div>
      </AnchorLink>
    </li>
  )
}

LocationItem.propTypes = {
  location: PropTypes.object,
  isNavActive: PropTypes.bool,
  currentSubMenuRef: PropTypes.object,
  pageName: PropTypes.string,
}

const LocationSlide = ({
  locations,
  locationNavIndex,
  currentSubMenuRef,
  pageName,
}) => {
  const locationItems = locations.map((location, index) => (
    <LocationItem
      key={index}
      location={location}
      isNavActive={index === locationNavIndex}
      currentSubMenuRef={currentSubMenuRef}
      pageName={pageName}
    />
  ))

  return <>{locationItems}</>
}

LocationSlide.propTypes = {
  locations: PropTypes.array,
  locationNavIndex: PropTypes.number,
  currentSubMenuRef: PropTypes.object,
  pageName: PropTypes.string,
}

export default LocationSlide
