import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { FaChevronRight } from "react-icons/fa"

const JobItem = ({ job }) => {
  const { slug, title, shortDescription } = job
  return (
    <Link to={`/join-us/${slug}`}>
      <div className="group p-6 text-gray-500 bg-white rounded-lg hover:shadow-xl transition duration-300 ease-out h-full">
        <p className="text-gray-700 text-xl sm:text-2xl font-serif">{title}</p>
        <p className="text-base mt-4 mb-2">{shortDescription}</p>
        <div className="flex items-center space-x-2 text-base group-hover:text-forhover">
          <p>暸解更多</p>
          <FaChevronRight />
        </div>
      </div>
    </Link>
  )
}
const Job = ({ location, index, locationRefs }) => {
  const { job, locationName } = location
  const jobList = job.map((job, index) => <JobItem job={job} key={index} />)

  return (
    <div
      className={`grid lg:grid-cols-2 gap-6 sm:gap-12 ${
        index !== 0 ? "pt-12 sm:pt-16" : ""
      }`}
      ref={locationRefs.current[index]}
    >
      <div id={locationName} className="absolute -mt-24">
        <span className="hidden">{locationName}</span>
      </div>
      {job.length === 0 && (
        <div className="text-gray-500 border-gray-500 w-full text-center col-span-2">
          <p className="text-center">{`${locationName}目前暫無職缺`}</p>
        </div>
      )}
      {jobList}
    </div>
  )
}

JobItem.propTypes = {
  job: PropTypes.object,
}
Job.propTypes = {
  location: PropTypes.object,
  index: PropTypes.number,
  locationRefs: PropTypes.object,
}

export default Job
