import { graphql, useStaticQuery } from "gatsby"
import useLocationData from "./useLocationData"

const useLocationJobData = () => {
  const { locationDict, locations } = useLocationData()
  const data = useStaticQuery(graphql`
    {
      allContentfulJobOpening(sort: { fields: order, order: ASC }) {
        nodes {
          id
          slug
          title
          shortDescription {
            shortDescription
          }
          location {
            name
          }
        }
      }
    }
  `)
  data.allContentfulJobOpening.nodes.forEach(node => {
    const {
      id,
      slug,
      title,
      shortDescription: { shortDescription },
      location: { name },
    } = node

    const job = {
      id: id,
      slug: slug,
      title: title,
      shortDescription: shortDescription,
      locationName: name,
    }
    // 把工作分配到所屬地區
    locationDict[job.locationName].push(job)
  })

  locations.forEach(location => {
    location.job = locationDict[location.locationName]
  })
  const locationJobs = locations

  return { locationJobs }
}

export default useLocationJobData
