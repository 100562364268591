import React from "react"
import PropTypes from "prop-types"
import ReactMarkdown from "react-markdown"
import rehypeRaw from "rehype-raw"

const BenefitItem = benefit => {
  const {
    benefit: {
      title,
      icon: {
        file: { url },
      },
      description: { description },
    },
  } = benefit
  return (
    <div className="middle-border sm:px-6 pb-6 min-h-[230px] sm:min-h-[300px]">
      <div className="flex w-24 h-24 mx-auto">
        <img src={url} alt={title} height="96" width="96" />
      </div>
      <div className="text-center">
        <h4 className="my-3 border-b border-primary text-gray-700 inline-block text-xl">
          {title}
        </h4>
        <ReactMarkdown
          className="text-left text-gray-500 text-base"
          rehypePlugins={[rehypeRaw]}
        >
          {description}
        </ReactMarkdown>
      </div>
    </div>
  )
}
const Benefit = ({ benefits: { nodes } }) => {
  const benefits = nodes.map((benefit, index) => (
    <BenefitItem benefit={benefit} key={index} />
  ))
  return <>{benefits}</>
}

BenefitItem.propTypes = {
  benefit: PropTypes.object,
}
Benefit.propTypes = {
  benefits: PropTypes.object,
}

export default Benefit
